var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('baseTable',_vm._b({attrs:{"columns":_vm.columns,"actionList":_vm.actionList,"editFields":_vm.editFields,"tableData":_vm.tableData,"rowSelection":_vm.rowSelection,"showExpandedRow":_vm.showExpandedRow,"expandIconColumnIndex":_vm.expandIconColumnIndex,"fieldSlots":_vm.fieldSlots,"pagination":_vm.pagination},on:{"actionHandler":_vm.actionHandler,"editHandler":_vm.editFieldHandler,"paginationChange":_vm.paginationChange},scopedSlots:_vm._u([{key:"bdCode",fn:function(ref){
var index = ref.index;
var record = ref.record;
return [_vm._t("bdCode",null,{"index":index,"record":record})]}},(_vm.showExpandedRow)?{key:"expandedRowRender",fn:function(ref){
var record = ref.record;
var index = ref.index;
var indent = ref.indent;
var expanded = ref.expanded;
return [_vm._t("expandedRowRender",null,{"record":record,"index":index,"indent":indent,"expanded":expanded})]}}:null,(_vm.showExpandedRow)?{key:"expandIcon",fn:function(ref){
var expanded = ref.expanded;
var record = ref.record;
return [_vm._t("expandIcon",null,{"expanded":expanded,"record":record})]}}:null],null,true)},'baseTable',_vm.$attrs,false),[_c('template',{slot:"clfTitle"},[_c('span',[_vm._v("材料费(元) "),_c('a-tooltip',{attrs:{"placement":"bottom"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("材料费用暂不支持明细设置，其中主材价格为0")])]),_c('img',{staticStyle:{"margin-top":"-2px"},attrs:{"src":require("@/assets/img/help-icon.png"),"alt":""}})],2)],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }