





























































import Vue from 'vue';
import baseTable from './baseTable.vue';
import { TableActionType } from '@/interface/costQuotaLibrary';
const columns = [
  {
    title: '清单编码',
    key: 'bdCode',
  },
  {
    title: '项目名称',
    key: 'bdName',
  },
  {
    title: '项目特征',
    key: 'projectAttr',
    type: 'textarea',
    placeholder: '请填写项目特征',
  },
  {
    title: '单位',
    key: 'unit',
  },
  {
    title: '综合单价(元)',
    key: 'price',
  },
];
const actionList = [
  {
    type: TableActionType.COST,
    name: '成本组价',
  },
];
const editFields = ['projectAttr'];
export default Vue.extend({
  name: 'detailedListTAble',
  components: { baseTable },
  props: {
    columns: {
      type: Array,
      default: () => columns,
    },
    /**
     * 操作列表
     */
    actionList: {
      type: Array,
      default: () => actionList,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    /**
     * 需要自定义字段
     */
    fieldSlots: {
      type: Array,
      default: () => [],
    },
    /**
     * 需要编辑字段
     */
    editFields: {
      type: Array,
      default: () => editFields,
    },
    showExpandedRow: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否展示最左侧选择框
     */
    rowSelection: {
      type: Object,
      default: null,
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  computed: {
    expandIconColumnIndex(): number {
      return this.columns.length + (this.rowSelection ? 1 : 0);
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 编辑失焦事件
     */
    editFieldHandler({ record, key, index, eventName }) {
      if (eventName === 'focus') return;
      this.$emit('editHandler', { record, key, index });
    },
    actionHandler({ record, key, index }) {
      this.$emit('actionHandler', { record, key, index });
    },
    paginationChange(pagination) {
      this.$emit('paginationChange', pagination);
    },
  },
});
