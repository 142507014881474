





















































import Vue from 'vue';
import baseTable from './baseTable.vue';
import { MaxNumberLimit } from '@/interface';
import { TableActionType } from '@/interface/costQuotaLibrary';
import { getPrice } from '../index';
const columns = [
  {
    title: '定额编码',
    key: 'bdCode',
    width: '100px',
  },
  {
    title: '定额名称',
    key: 'bdName',
    width: '200px',
  },
  {
    title: '单位',
    key: 'unit',
    width: '100px',
  },
  {
    title: '定额综合单价(元)',
    key: 'price',
  },
  {
    title: '人工费(元)',
    key: 'rfee',
  },
  {
    key: 'cfee',
  },
  {
    title: '机械费(元)',
    key: 'jfee',
  },
  {
    title: '管理费及利润(元)',
    key: 'managerFee',
  },
  {
    title: '定额工程量',
    key: 'quantity',
  },
  {
    title: '定额合价(元)',
    key: 'total',
  },
];
const actionList = [
  {
    type: TableActionType.DELETE,
    name: '移除',
  },
];
const editFields = ['quantity'];

export default Vue.extend({
  name: 'quotaTable',
  components: { baseTable },
  props: {
    columns: {
      type: Array,
      default: () => columns,
    },
    /**
     * 操作列表
     */
    actionList: {
      type: Array,
      default: () => actionList,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    /**
     * 需要自定义字段
     */
    fieldSlots: {
      type: Array,
      default: () => [],
    },
    /**
     * 需要编辑字段
     */
    editFields: {
      type: Array,
      default: () => editFields,
    },
    parentIndex: {
      type: Number,
      default: null,
    },
    parentRecord: {
      type: Object,
      default: () => null,
    },
    /**
     * 是否展示最左侧选择框
     */
    isCanSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.$emit('rowSelectChange', { selectedRowKeys, selectedRows });
        },
      };
    },
    getTotal() {
      return (record) => {
        let val = (record.price * (Number(record.quantity) || 1)).toFixed(2);
        val = isNaN(Number(val)) ? '--' : val;
        record.total = val;
        return val;
      };
    },
    price() {
      return (record) => {
        let val = getPrice(record);
        record.price = val;
        return val;
      };
    },
  },
  methods: {
    /**
     * 编辑失焦事件
     */
    editFieldHandler({ record, key, index, eventName }) {
      const eventList = {
        blur: this.editBlurHandler,
        focus: this.editFocusHandler,
      };
      eventList[eventName]({ record, key, index, eventName });
    },
    editFocusHandler({ record, key, index, eventName }) {
      const isQuantity = key === 'quantity';
      if (!isQuantity) return;
      record.quantity = record?.equation || record.quantity;
      record['originalEquation'] = JSON.parse(JSON.stringify(record.quantity));
      this.editEmit(record, key, index, eventName);
    },
    editBlurHandler({ record, key, index, eventName }) {
      const isQuantity = key === 'quantity';
      if (isQuantity) {
        const isQuantityFail = this.quantityHandler(record);
        if (!isQuantityFail) {
          this.editEmit(record, key, index, eventName);
          return;
        }
      }
      const value = parseFloat(Number(record[key]).toFixed(isQuantity ? 6 : 2));
      record[key] = value;
      if (value <= 0 || value >= MaxNumberLimit.AMOUNT) {
        record.isError = 1;
        if (!record?.customCellClass) record.customCellClass = {};
        record.customCellClass[key] = 'fail-term';
        this.editEmit(record, key, index, eventName);
        let msg = '请输入有效数值';
        if (value <= 0) msg = '定额工程量必须大于0';
        if (value >= MaxNumberLimit.AMOUNT)
          msg = `不能大于${MaxNumberLimit.AMOUNT}`;
        return this.$message.warning(msg);
      }
      record.isError = 0;
      if (record?.customCellClass) {
        delete record.customCellClass[key];
      }
      // this.checkFailField(record);
      this.editEmit(record, key, index, eventName);
    },
    /**
     * 工程量处理
     */
    quantityHandler(record) {
      const failHandler = () => {
        record.isError = 1;
        if (!record.customCellClass) {
          record.customCellClass = {};
        }
        record.customCellClass['quantity'] = 'fail-term';
        this.$message.warning('请输入数字以及+-*/运算符');
      };
      record.quantity = (record.quantity + '').slice(0, 32);
      let averageUnit = 1 / record.unitnbr;
      // 初始没有修改过值
      if (
        record?.equation === null &&
        record['originalEquation'] === record.quantity
      ) {
        averageUnit = 1;
      } else {
        record.equation = record.quantity;
      }
      const reg = /[^0-9|\-|\*|\+|\/|\.|(|)]/g;
      if (record.equation !== null && reg.test(record.equation)) {
        failHandler();
        return false;
      }
      try {
        record.quantity = (
          new Function(`return ${record.quantity}`)() * averageUnit
        ).toFixed(6);
        if (record.customCellClass) delete record.customCellClass['quantity'];
        return true;
      } catch (err) {
        console.log('quotaTable.vue|250====>', err);
        record.quantity = 0;
        failHandler();
        return false;
      }
    },
    editEmit(record, key, index, eventName) {
      if (eventName === 'focus') return;
      this.$emit('editHandler', {
        record,
        key,
        index,
        parentIndex: this.parentIndex,
        parentRecord: this.parentRecord,
        eventName,
      });
    },
    /**
     * 人材机是否有错误值
     */
    isRCJFail(record) {
      const checkField = ['rfee', 'cfee', 'jfee'];
      let num = 0;
      checkField.forEach((item) => {
        if (Number(record[item]) === 0) num++;
      });
      if (num >= 3) {
        checkField.forEach((item) => {
          if (!record.customCellClass) record.customCellClass = {};
          record.customCellClass[item] = 'fail-term';
        });
        record.isError = 1;
        this.$message.warning('人材机必须有一位大于0');
        return true;
      }
      return false;
    },
    /**
     * 检查字段是否有错误
     */
    checkFailField(record) {
      let errorNum = 0;
      let nullNum = 0;
      editFields.forEach((item) => {
        const isFail = !Number(record[item]);
        if (!isFail) {
          if (record?.customCellClass) {
            delete record.customCellClass[item];
          }
        }
        if (isFail) errorNum++;
        if (record[item] === '') nullNum++;
      });
      if (!errorNum) record.isError = 0;
      if (!nullNum) record.isNull = 0;
    },
    actionHandler({ record, key, index, e }) {
      this.$emit('actionHandler', {
        record,
        key,
        index,
        parentIndex: this.parentIndex,
        parentRecord: this.parentRecord,
        e,
      });
    },
    selectedData({ record, index, e }) {
      this.$emit('selectedData', { record, index, e });
    },
    preOperation(e) {
      this.$emit('preOperation', e);
    },
  },
});
