



































































































import Vue from 'vue';
import detailedListTAble from './detailedListTable.vue';
import quotaTable from './quotaTable.vue';
export default Vue.extend({
  name: 'combinationTable',
  components: { detailedListTAble, quotaTable },
  props: {
    detailedListBind: {
      type: Object,
      default: () => null,
    },
    quotaFunction: {
      type: Object,
      default: () => null,
    },
    detailedListFunction: {
      type: Object,
      default: () => null,
    },
    quotaBind: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    quotaFieldSlots() {
      if (!this.quotaBind) {
        return [];
      }
      return this.quotaBind.fieldSlots || [];
    },
  },
  data() {
    return {
      expandedRowKeys: [] as string[],
    };
  },
  methods: {
    getRandom(min, max) {
      return Math.ceil(Math.random() * (max - min) + min);
    },
    setExpandedRowKeys(keys: string[]) {
      const expandedRowKeys = this.expandedRowKeys;
      this.expandedRowKeys = [...expandedRowKeys, ...keys];
    },
    clearExpandedRowKeys() {
      this.expandedRowKeys = [];
    },
    expandChange({ expanded, record }) {
      const key = record.sequenceNbr;
      const expandedRowKeys = this.expandedRowKeys;
      if (expanded) {
        const index = expandedRowKeys.indexOf(key);
        this.expandedRowKeys.splice(index, 1);
        return;
      }
      this.setExpandedRowKeys([key]);
    },
    actionHandler({ record, key, index }) {
      this.$emit('actionHandler', { record, key, index });
    },
    paginationChange(pagination) {
      this.$emit('paginationChange', pagination);
    },
  },
});
